.application-dropdown {
  flex: 1;
  display: flex;
  flex-direction: column;

  &--button {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  &--container {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between;
  }

  &--bottom {
    max-height: 14rem;
    opacity: 1;
    transition: all .5s ease-in;
  }

  &--bottom-closed {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease-in;
  }

  &--down {
    transform: scaleY(-1);
  }
}
