.chart {
  &__canvas {
    width: 100% !important;
  }

  &__wrapper {
    height: 100%;
    position: relative;
  }

  &__no-data {
    color: var(--color-alabaster);
    font-size: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
