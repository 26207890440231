.analytics-engagement {
  &--ghost-dropdown {
    height: 4.4rem;
    display: flex;
    flex: 1;
    width: 100%;
  }

  &--right-component {
    display: flex;
    flex-direction: row;

    @media (--tablet) {
      flex-direction: column;
    }
  }

  :global {
    .swiper-slide {
      width: auto;
    }

    .swiper-button-disabled {
      opacity: .5;
    }
  }

  &__arrow-btn {
    font-size: 0;

    > svg > path {
      stroke: var(--color-main-dynamic);
      transition: stroke .2s;
    }

    &:not(:global .swiper-button-disabled) {
      cursor: pointer;

      &:hover > svg > path {
        stroke: var(--color-hover-dynamic);
      }
    }
  }

  &__prev-btn {
    margin-left: 2rem;
  }

  &__prev-btn,
  &__next-btn {
    @media (--tablet) {
      display: none;
    }
  }

  &--mobile-right-component {
    flex-direction: row;
  }

  &__mobile-prev-btn {
    margin-left: 3rem;
  }
}
