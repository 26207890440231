.player {
  background: var(--color-cod-gray);
  border-top: 1px solid var(--color-cod-gray-400);
  bottom: 58px;
  height: 100px;
  left: 0;
  position: fixed;
  right: 0;
  transform: translateY(100%);
  transition: transform .2s, left .2s, right .2s, height .2s;
  transition-timing-function: ease-out;
  z-index: var(--mini-player-z-index);

  &--artist {
    bottom: 0;
  }

  @media (--from-tablet-landscape) {
    bottom: 0;
    height: 80px;
    left: 0;
    overflow: visible;
  }

  @media (--from-desktop) {
    height: 88px;
  }

  &--maxi-player {
    height: calc(100% - var(--header-height) - 58px);
    overflow-x: hidden;
    overflow-y: auto;

    @media (--from-tablet-landscape) {
      height: calc(100% - var(--header-height));
    }
  }

  &--filters-open {
    @media (--from-tablet-landscape) {
      left: 0;
    }
  }

  &--open {
    transform: translateY(0);
    transition-timing-function: ease-in;
  }
}
