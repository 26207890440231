.dropdown {
  position: relative;

  &__button {
    color: var(--color-gray-200);
    letter-spacing: .0071em;
    transition: color .2s;
    white-space: nowrap;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid inherit;
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: border-top-color .2s;
    }

    &[data-focus-visible-added],
    &:hover {
      color: var(--color-gin);
    }

    &--maxi {
      font: 1.4rem/2.4rem var(--font-gerstner);
      padding-right: 16px;
    }

    &--mini {
      font: 500 1.2rem/1.6rem var(--font-gerstner);
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__options {
    display: none;
    position: absolute;
    z-index: 90;
    background-color: var(--color-cod-gray-300);
    box-shadow: 0 8px 24px rgb(18 18 18 / 75%);
    border-radius: 4px;

    &--open-upward {
      bottom: 100%;
    }

    &--open-downward {
      top: 100%;
    }

    &--open {
      display: block;
    }

    &--left-side {
      right: 0;
      margin-right: var(--left-padding);
      margin-top: calc(-1 * var(--top-padding));
      padding-block-start: -10px;
    }

    &--right-side {
      left: 0;
      padding-block-start: 5px;
    }
  }

  &__option {
    align-items: center;
    color: var(--color-gray-200);
    cursor: pointer;
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .001em;
    min-height: 40px;
    min-width: 250px;
    padding-left: 10px;
    position: relative;

    svg > path {
      transition: fill .2s;
      fill: var(--color-gray-200);
    }

    svg > g {
      transition: fill .2s;
      fill: var(--color-gray-200);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);

        svg > path {
          fill: var(--color-gin);
        }

        svg > g > path {
          fill: var(--color-gin);
        }
      }
    }

    &-btn {
      align-items: center;
      color: inherit;
      display: flex;
      flex-grow: 1;
      gap: 18px;
      padding-right: 15px;
      transition: color .2s;

      &-label {
        align-items: center;
        display: flex;
        flex-grow: 1;
        justify-content: flex-start;
        text-align: start;
      }
    }
  }
}
