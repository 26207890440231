.analytics-preview {
  background-color: transparent;
  border: 0 solid var(--color-cod-gray-500);

  @media (--from-tablet-landscape) {
    background-color: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-500);
  }

  &--card-container {
    flex-direction: column;
    display: flex;

    @media (--from-tablet-landscape) {
      flex-direction: row;
      display: flex;
    }
  }

  &--separator {
    display: none;

    @media (--from-tablet-landscape) {
      width: 1px;
      background-color: var(--color-cod-gray-500);
      margin-top: 1rem;
      margin-bottom: 1rem;
      display: flex;
    }
  }
}
