.versions-menu {
  display: flex;
  flex-direction: column;

  &__item {
    align-items: center;
    display: flex;
    padding: 12px 16px;
  }

  &__item-play {
    flex: 0 0 auto;
    margin-right: 22px;
  }

  &__item-title {
    color: var(--color-gray-200);
    flex: 1 1 auto;
    font: 500 1.6rem/2.4rem var(--font-gerstner);
  }

  &__item-duration {
    color: var(--color-mercury);
    flex: 0 0 auto;
    font: 1.4rem/2.4rem var(--font-gerstner);
    margin-right: 5px;
  }

  &__item-button {
    flex: 0 0 auto;
    margin: 0 8px;
  }
}
