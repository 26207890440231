.overview-chart {
  background-color: var(--color-cod-gray-300);
  border: 1px solid var(--color-cod-gray-500);
  padding: 22px 16px;

  &--dropdown {
    >li {
      background-color: var(--color-cod-gray-400);
    }
  }

  &--title-container {
    display: flex;
    align-items: center;
  }

  &--title {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &--period {
    margin-left: auto;
  }

  &__chart {
    margin-top: 4rem;
    height: 40rem;
  }
}
