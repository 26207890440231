.top-locations {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 32px;

  @media (--from-tablet-landscape) {
    flex-direction: row;
  }

  >div {
    flex: 1;
  }
}
