.manage-profile {
  margin: 2rem;

  &--info {
    margin: 0 2rem;

    @media (--from-tablet-landscape) {
      margin: 0;
      margin-bottom: 2rem;
    }
  }

  &--divider {
    background-color: var(--color-cod-gray-500);
    height: .1rem;
    flex: 1;
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
  }

  &--horizontal-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    background-color: transparent;
  }

  &--artist-header-container {
    background-color: transparent;
    flex: 1;
    margin: 0;
  }

  &--artist-header {
    padding: 0;
    margin: 0 -2rem;

    @media (--from-tablet-landscape) {
      padding: 0 3rem;
      background-color: var(--color-cod-gray-300);
      flex: 1;
      margin: 0 2rem 0 0;
      border: .1rem solid var(--color-cod-gray-500);
    }
  }

  &--artist-sections {
    margin: 0 -2rem;

    @media (--from-tablet-landscape) {
      margin: 0 2rem;
    }
  }

  &--form-container {
    background-color: transparent;
  }
}
