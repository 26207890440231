.my-playlists-category-item {
  padding: 8px;

  @media (--mobile) {
    padding: 10px 20px;
  }

  &__button {
    align-items: center;
    color: var(--color-gray-200);
    display: flex;
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .1px;
    transition: color .2s;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }

    > span {
      margin-inline-start: 8px;
      overflow: hidden;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }

    > svg {
      flex: 0 0 24px;
    }

    > svg > path {
      fill: currentColor;
    }
  }
}
