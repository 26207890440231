.other-artists {
  &__right-container {
    @media (--from-tablet-landscape) {
      display: flex;
    }
  }

  &__arrow-btn {
    font-size: 0;

    > svg > path {
      stroke: var(--color-main-dynamic);
      transition: stroke .2s;
    }

    &:not(:global .swiper-button-disabled) {
      cursor: pointer;

      &:hover > svg > path {
        stroke: var(--color-hover-dynamic);
      }
    }
  }

  &__loading {
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;

    &-image {
      background-color: var(--color-cod-gray-500);
    }

    &-item {
      height: 264px;
      min-width: 192px;
      width: 192px;
      display: inline-block;
      margin-right: 16px;

      &-round {
        border-radius: 100%;
        background-color: var(--color-cod-gray-400);
      }
    }
  }

  &__prev-btn {
    margin-left: 2rem;
  }

  &__prev-btn,
  &__next-btn {
    @media (--tablet) {
      display: none;
    }
  }

  :global {
    .swiper-slide {
      width: auto;
    }

    .swiper-button-disabled {
      opacity: .5;
    }
  }
}
