.ghost-loading-elements {
  --highlight-color: var(--color-tundora);
  flex: 1;
  margin: 1rem 0 0;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      --highlight-color: var(--color-gin);
    }
  }

  @media (--from-tablet-landscape) {
    margin: 1rem 1rem 0;
  }

  &--container {
    display: none;

    @media (--from-tablet-landscape) {
      display: inline;
    }
  }

  &--large {
    height: 200px;
    min-width: 180px;

    @media (--from-tablet-landscape) {
      margin: 0 -1rem;
    }
  }

  &--mobile-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &--mobile-container-left {
    width: 50%;
  }

  &--mobile-container-right {
    flex-direction: column;
    width: 50%;
    transform: scaleX(-1);
  }

  &--mobile-container-element {
    width: 100%;
    height: 100%;
  }
}
