.ghost-loading-elements {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1.6rem;
  background: var(--color-cod-gray-300);

  &--top {
    background-color: transparent;
    margin: 0;
  }

  &--footer {
    margin-top: auto;
  }

  &--button {
    background-color: transparent;
    margin: 0;
  }
}
