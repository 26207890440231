.your-tracks {
  &__subheader-container {
    display: flex;
    flex-direction: column;
    gap: var(--top-padding);

    @media (--from-tablet-landscape) {
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      width: 100%;
    }
  }

  &__search-container {
    @media (--from-tablet-landscape) {
      width: 273px;
    }
  }

  &__see-more-section {
    padding-right: 16px;
  }

  &__see-less-btn {
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    transition: color .2s;

    &--active {
      color: var(--color-hover-dynamic);
    }

    &--deactivate {
      color: var(--color-tundora);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
      }
    }
  }
}
