.dashboard {
  &__container {
    margin: 32px 16px 144px;
    display: flex;
    flex-direction: column;

    section {
      scroll-margin-top: var(--header-height);

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    @media (--from-tablet-landscape) {
      margin: 32px 32px 144px;

      section:not(:first-child) {
        margin-top: 72px;
      }
    }

    &__top-section {
      gap: 3.2rem;

      @media (--from-tablet-landscape) {
        display: flex;
      }

      &--left {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 22px;
      }

      &--right {
        flex-basis: 360px;
        display: flex;
        flex-direction: column;
        gap: 22px;
      }
    }
  }
}
