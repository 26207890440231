body {
  padding-top: var(--header-height);

  .relative-link {
    position: relative;
  }

  @media (hover: hover) {
    .relative-link:hover {
      color: var(--color-alabaster);
    }
  }

  &[data-focus-visible-added] {
    background-color: var(--color-black);
    outline: 1px solid var(--color-dodger-blue);
  }

  .link {
    color: var(--color-gin);
  }

  .link-hover {
    color: var(--color-main-dynamic);
    font: 500 1.4rem/2rem var(--font-gerstner);
    letter-spacing: .35px;
    transition: color .5s;

    @media (hover: hover) {
      &:hover {
        color: var(--color-hover-dynamic);
      }
    }
  }

  .underline-link {
    color: var(--color-gin);
    position: relative;
    text-decoration: none;
  }

  @media (hover: hover) {
    .underline-link:hover,
    .underline-link:hover * {
      text-decoration: underline;
    }

    .link:hover {
      color: var(--color-gin);
    }
  }

  .load-more-in-view {
    height: 1px;
  }
}
