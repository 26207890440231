.analytics-card {
  &--small {
    --text-color: var(--color-tundora);
    --tooltip-opacity: 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-bottom: 1rem;
    background-color: var(--color-cod-gray-300);
    border: 1px solid var(--color-cod-gray-500);

    @media (hover: hover) {
      &:hover {
        --text-color: var(--color-gin);
        --tooltip-opacity: 1;
      }
    }

    @media (--from-tablet-landscape) {
      margin: 2rem;
      flex-direction: row;
      align-items: center;
      background-color: transparent;
      border: none;
    }
  }

  &--large {
    --text-color: var(--color-gin);
    --tooltip-opacity: 0;
    height: 200px;
    width: auto;
    min-width: 180px;
    display: flex;
    flex-direction: row;
    margin: 0;
    margin-bottom: 1rem;
    background-color: transparent;
    border: 1px solid var(--color-cod-gray-500);

    @media (hover: hover) {
      &:hover {
        --text-color: var(--color-gin);
        --tooltip-opacity: 1;
        background-color: var(--color-cod-gray-300);
      }
    }

    @media (--from-tablet-landscape) {
      flex-direction: row;
      background-color: transparent;
    }
  }

  &--left-small {
    padding: 1.5rem;
    flex-direction: row;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-content: center;

    @media (--from-tablet-landscape) {
      padding: 0;
      flex-direction: column;
    }
  }

  &--left-large {
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    display: flex;
    padding: 2rem 1rem 2rem 1.5rem;
  }

  &--right-values {
    text-align: end;
    margin-top: 1rem;

    @media (--from-tablet-landscape) {
      text-align: start;
    }
  }

  &--title {
    transition: color .2s;
    font: 500 1.6rem var(--font-gerstner);
    color: var(--color-gin);

    @media (--from-tablet-landscape) {
      color: var(--text-color);
    }
  }

  &--tooltip {
    opacity: 1;
    transition: opacity .2s;

    > svg > path {
      fill: var(--color-tundora);
    }

    @media (--from-tablet-landscape) {
      opacity: var(--tooltip-opacity);

      > svg > path {
        fill: var(--color-gin);
      }
    }
  }

  &--title-section {
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    display: flex;

    @media (--from-tablet-landscape) {
      margin-bottom: 1rem;
    }
  }

  &--growth-section-small {
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: row;
    display: flex;
    justify-content: flex-end;

    @media (--from-tablet-landscape) {
      justify-content: flex-start;
    }
  }

  &--growth-section-large {
    margin-top: 1rem;
    align-items: center;
    gap: 1rem;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
  }

  &--value {
    font: 500 2.4rem/1.6rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &--value-inline-small {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    @media (--from-tablet-landscape) {
      justify-content: flex-start;
    }
  }

  &--value-inline-large {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (--from-tablet-landscape) {
      justify-content: flex-start;
    }
  }

  &--chart {
    height: 16px;
    width: 16px;
  }

  &--chart-green {
    > path {
      fill: var(--color-fruit-salad);
      stroke: var(--color-fruit-salad);
    }
  }

  &--percentage {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
  }

  &--percentage--rising {
    color: var(--color-fruit-salad);
  }

  &--percentage--descending {
    color: var(--color-burnt-sienna);
  }

  &--percentage-text-small {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
    opacity: var(--tooltip-opacity);
    display: none;
    transition: opacity .2s;

    @media (--from-tablet-landscape) {
      display: flex;
    }
  }

  &--percentage-text-large {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
    transition: opacity .2s;
    opacity: 1;

    @media (--from-tablet-landscape) {
      opacity: var(--tooltip-opacity);
    }
  }

  &--percentage-inline {
    display: flex;
    justify-content: flex-end;

    @media (--from-tablet-landscape) {
      flex-direction: row;
    }
  }

  &--percentage-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
