.submit-music-form {
  justify-content: center;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  display: flex;
  margin: 0 2rem;

  &--container {
    max-width: 45rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 2rem;

    @media (--from-tablet-landscape) {
      max-width: 55rem;
    }
  }

  &__progress-popup-title {
    font: 1.6rem/1.8rem var(--font-gerstner);
    display: flex;
    align-items: center;

    @media (--from-tablet-landscape) {
      font: 2.4rem/3.2rem var(--font-gerstner);
    }

    svg {
      margin-right: 1rem;
      animation: var(--spin-animation) 2s linear infinite;
    }
  }
}
