.top-weekly-tracks {
  background-color: var(--color-cod-gray-300);
  border: 1px solid var(--color-cod-gray-500);
  padding: 22px 16px;

  &--title-container {
    display: flex;
    align-items: center;
  }

  &--title {
    font: 500 1.4rem/2rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &--sub-title {
    font: 500 1.4rem/2.4rem var(--font-gerstner);
    color: var(--color-boulder);
    margin-left: auto;
  }

  &__your-tracks {
    display: flex;
    align-items: center;

    svg > g > path {
      fill: var(--color-main-dynamic);
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover {
        svg > g > path {
          fill: var(--color-hover-dynamic);
        }
      }
    }
  }
}
