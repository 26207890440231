.artist-portal-banner {
  &__swiper {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1.6rem;
    background-color: var(--color-gin);
    outline: 1px solid var(--color-cod-gray-500);
  }

  &__container-outer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__title {
    font: 400 1.4rem/2rem var(--font-gerstner);
    opacity: .5;
    margin-bottom: .8rem;
  }

  &__message {
    font: 400 2.4rem/2.4rem var(--font-gerstner);
    margin-bottom: 3.2rem;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__arrows {
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    right: 1.6rem;
    bottom: 2.4rem;
    z-index: 1;
  }

  &__arrow-right {
    cursor: pointer;
  }

  &__arrow-left {
    transform: rotate(180deg);
    margin-right: .9rem;
    cursor: pointer;
    margin-bottom: .4rem;
  }

  &__button {
    border: 1px solid var(--color-gin);
    color: var(--color-white);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    background-color: var(--color-black);
    display: flex;
    padding: .8rem 1.2rem;
    align-items: center;
  }

  &_button-text {
    text-align: center;
  }

  &__button-triangle {
    margin-left: 1.7rem;
  }
}
