/* For simplicity and consistency, track-list-item and track-list components are both using
the styles defined in this file.
track-list-item does not have its own .module.css file. */
.track-list {
  --hover-button-opacity: 0;

  &__title {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    letter-spacing: .006em;
    margin-bottom: 0;
    margin-right: auto;

    span {
      font-size: 1.8rem;
      letter-spacing: .0277em;
      line-height: 2.4rem;
    }
  }

  &__list {
    width: 100%;
    margin-bottom: 20px;
  }

  &__list-heading {
    align-items: center;
    border-bottom: 1px solid var(--color-cod-gray-400);
    color: var(--color-gray-200);
    font: 500 1rem/1.6rem var(--font-gerstner);
    cursor: default;
    letter-spacing: .08em;
    text-align: left;
    text-transform: uppercase;
  }

  &__list-item--clickable {
    cursor: pointer;

    @media (hover: hover) {
      &:hover {
        --hover-button-opacity: 1;
        background-color: var(--color-cod-gray-300);
      }
    }
  }

  &__draggable-item-wrapper--is-dragging &__list-item {
    background-color: var(--color-cod-gray-300);
    color: var(--color-gin);
  }

  &__list-heading,
  &__list-item,
  &__download-accordion {
    align-items: center;
    display: grid;
    padding: 8px 0;
  }

  &__list-heading,
  &__list-item {
    max-width: 100%;

    &--is-album-restricted {
      opacity: .25;
      pointer-events: none;
    }

    &--dragging-over {
      border-bottom: 2px solid var(--color-main-dynamic);
    }

    /* columns width definitions.
     TODO: add column definitions for other presets */
    &--stream,
    &--offline-crate {
      grid-template-columns: [count] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [title] 352px
          [bpm] 69px [key] 69px [time-date] 69px [genre] 198px [actions] minmax(120px, 1fr);
      }
    }

    &--stream-trending {
      grid-template-columns: [count] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [title] 352px
          [bpm] 69px [key] 69px [time] 69px [genre] 198px [actions] minmax(120px, 1fr);
      }
    }

    &--album {
      grid-template-columns: [accordion] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 24px [title] 352px
          [bpm] 69px [key] 69px [time] 69px [actions] minmax(180px, 1fr);
      }
    }

    &--download {
      grid-template-columns: [count] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [accordion] 24px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 220px [actions] minmax(24px, auto);
      }

      @media (--from-desktop) {
        grid-template-columns:
          [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 280px [actions] minmax(24px, 120px);
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 350px [actions] minmax(24px, 200px);
      }
    }

    &--download-trending {
      grid-template-columns: [count] 24px [accordion] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [accordion] 24px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 220px [actions] minmax(24px, auto);
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 280px [actions] minmax(24px, 120px);
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 24px [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 350px [actions] minmax(24px, 200px);
      }
    }

    &--download-accordion {
      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 280px [actions] minmax(24px, 120px);
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 350px [actions] minmax(24px, 200px);
      }
    }

    &--download-accordion--trending {
      grid-template-columns: [count] 24px [accordion] 24px [title] 1fr [actions] 24px;

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 280px [actions] 120px;
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 24px [accordion] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [date] 120px [genre] 128px [versions] 350px [actions] minmax(120px, 200px);
      }
    }

    &--queue,
    &--online-crate {
      grid-template-columns: [count] 24px [title] 1fr [actions] 24px;

      @media (--from-tablet-landscape) {
        grid-template-columns:
          [count] 24px [title] minmax(230px, 1fr)
          [bpm] 69px [key] 69px [time-date] 108px [genre] 128px [versions] 220px [actions] minmax(24px, auto);
      }

      @media (--from-desktop) {
        grid-template-columns:
          [count] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [time-date] 108px [genre] 128px [versions] 280px [actions] minmax(24px, 120px);
      }

      @media (--from-large-desktop) {
        grid-template-columns:
          [count] 24px [title] minmax(auto, 1fr)
          [bpm] 69px [key] 69px [time-date] 108px [genre] 128px [versions] 350px [actions] minmax(24px, 200px);
      }
    }
  }

  &__sort-btn {
    display: none;
  }

  &__list-heading--sortable &__sort-btn {
    display: block;
  }

  &__date-timeago {
    color: var(--color-main-dynamic);
  }

  &__clickable-label {
    font: 500 1rem/1.6rem var(--font-gerstner);
    letter-spacing: .75px;
    text-align: left;
    color: var(--color-gray-200);
    text-transform: uppercase;
    transform: color .2s !important;
    display: flex;
    align-items: center;

    &--active {
      color: var(--color-gin);
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__heading-column,
  &__column {
    &--bpm,
    &--key,
    &--time,
    &--date,
    &--genre,
    &--versions,
    &--queue-download-version,
    &--versions-content,
    &--downloadable-pill {
      @media (--tablet) {
        display: none;
      }
    }

    &--versions {
      @media (min-width: 1191px) {
        display: block !important;
      }
    }

    &--bpm,
    &--key,
    &--time,
    &--date {
      @media (--from-tablet-landscape) {
        margin-right: 16px;
      }
    }

    &--title {
      display: flex;
      margin-right: 16px;
    }

    &--accordion {
      display: flex;
      align-items: center;
      height: 100%;
    }

    &--count,
    &--edit-mode-checkbox {
      justify-content: center;
    }

    &--trending-count {
      align-items: center;
      display: flex;
      font-weight: 500;
      height: min(40px, 100%);
      justify-content: center;
      position: relative;
      text-align: center;

      .track-list__list-item--trending-rising & {
        color: var(--color-fruit-salad);
      }

      .track-list__list-item--trending-rising &::after {
        border-bottom: 5px solid var(--color-fruit-salad);
      }

      .track-list__list-item--trending-dropping & {
        color: var(--color-burnt-sienna);
      }

      .track-list__list-item--trending-dropping &::after {
        border-top: 5px solid var(--color-burnt-sienna);
      }

      &::after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        bottom: 8px;
        content: '';
        height: 0;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: 0;
      }
    }

    &--stream-actions,
    &--download-actions,
    &--online-crate-actions,
    &--offline-crate-actions {
      gap: 8px;
      justify-self: end;
    }

    &--count,
    &--stream-actions,
    &--download-actions,
    &--edit-mode-checkbox {
      display: flex;
    }

    &--versions-content {
      @media (--from-tablet-landscape) {
        align-items: center;
        display: flex;
        gap: 8px;
      }

      @media (min-width: 1191px) {
        display: flex !important;
      }
    }

    &--downloadable-pill {
      line-height: 0;
    }
  }

  &__column {
    &--trending-count {
      padding-bottom: 10px;
    }
  }

  &__list-item,
  &__download-accordion {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .033em;
    text-align: left;
    transition: background-color .2s, color .2s;
  }

  &__download-accordion {
    grid-row-gap: 16px;
  }

  &__list-item--download-accordion-open,
  &__download-accordion {
    background-color: var(--color-cod-gray-300);
  }

  &__download-accordion-trigger {
    color: inherit;
    height: 24px;

    &--open > svg {
      transform: rotate(90deg);
    }
  }

  &__download-btn,
  &__downloadable-pill {
    border: 1px solid var(--color-gray-200);
    border-radius: 12px;
    box-sizing: content-box;
    color: var(--color-gray-200);
    font: 1rem/1.6rem var(--font-gerstner);
    height: 20px;
    letter-spacing: .75px;
    text-align: center;
    transition: border .2s, color .2s;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__downloaded {
      background-color: var(--color-tundora);
      border-color: var(--color-gray-200);
      color: var(--color-gray-200);
    }

    &-text {
      @media (min-width: 1256px) {
        display: inline !important;
      }
    }

    @media (hover: hover) {
      &:not([disabled])&:hover {
        border-color: var(--color-gin);
        background-color: var(--color-white);
        color: var(--color-black);

        path {
          fill: var(--color-black);
        }
      }
    }

    &[data-focus-visible-added] {
      border-color: var(--color-gin);
      color: var(--color-gin);
      outline: none;
    }

    &[disabled] {
      opacity: .25;
    }
  }

  &__downloadable-pill {
    > svg {
      display: none;
    }

    &:hover,
    &[data-focus-visible-added] {
      > span {
        display: none;
      }

      > svg {
        display: inline;
      }
    }
  }

  &__action-btn-desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }

    > svg {
      transition: fill .2s;
    }

    > svg > g > path {
      transition: fill .2s;
    }

    @media (hover: hover) {
      &:hover > svg {
        fill: var(--color-gin);
      }

      &:hover > svg > g > path {
        fill: var(--color-gin);
      }
    }
  }

  &__action-btn-desktop-track-is-in-list {
    > svg path {
      transition: fill .2s;
      fill: var(--color-main-dynamic) !important;
    }
  }

  &__three-dots-container {
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: -8px;
    min-width: 40px;
  }

  &__action-buttons-container {
    display: flex;
    transition: opacity .2 ease-in-out;
    opacity: 1;
  }

  &__share-button {
    display: flex;
    padding-right: calc(var(--left-padding) / 2);
    align-items: center;
    gap: var(--left-padding);

    @media (--mobile) {
      display: none;
    }

    @media (--from-tablet-landscape) {
      opacity: var(--hover-button-opacity);
    }
  }

  &__three-dots {
    opacity: 1;

    @media (--mobile) {
      display: flex;
    }
  }

  &__third-line-mobile-content {
    > *:not(:last-child)::after {
      color: inherit;
      content: ' / ';
    }
  }
}
