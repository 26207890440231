.artist-application {
  justify-content: center;
  flex: 1;
  align-items: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  display: flex;
  margin: 0 2rem;

  &--container {
    max-width: 45rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-bottom: 2rem;

    @media (--from-tablet-landscape) {
      max-width: 55rem;
    }
  }

  &--preview {
    background-color: var(--color-cod-gray-500);
    height: 7.4rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    &--column {
      height: 5.6rem;
      width: 0;
      padding-right: 2rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }

    &--image {
      height: 5.6rem;
      width: 5.6rem;
      margin-left: 1rem;
      object-fit: cover;
    }

    &--image-rounded {
      height: 5.6rem;
      width: 5.6rem;
      margin-left: 1rem;
      border-radius: 2.8rem;
      overflow: hidden;
      object-fit: cover;
    }

    &--text {
      font: 500 1.3rem var(--font-gerstner);
      color: var(--color-gin);
      white-space: nowrap;
      overflow: hidden;
      display: block;
      word-break: break-all;
      text-overflow: ellipsis;
    }

    &--text-thin {
      font: 300 1.2rem var(--font-gerstner);
      color: var(--color-gin);
      white-space: nowrap;
      overflow: hidden;
      display: block;
      word-break: break-all;
      text-overflow: ellipsis;
    }

    &--text-small {
      font: 300 1rem var(--font-gerstner);
      color: var(--color-gray-200);
      white-space: nowrap;
      overflow: hidden;
      display: block;
      word-break: break-all;
      text-overflow: ellipsis;
    }
  }

  &--add-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__progress-popup-title {
    font: 1.6rem/1.8rem var(--font-gerstner);
    display: flex;
    align-items: center;

    @media (--from-tablet-landscape) {
      font: 2.4rem/3.2rem var(--font-gerstner);
    }

    svg {
      margin-right: 1rem;
      animation: var(--spin-animation) 2s linear infinite;
    }
  }

  &--begin-action-modal-title {
    font: 300 1.4rem var(--font-gerstner);
    opacity: .5;
  }

  &--begin-action-modal-body {
    font: 300 1.4rem var(--font-gerstner);
    text-align: left;
    white-space: pre-line;
  }

  &--checkbox {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin: 16px 0;
  }

  &--section-header {
    font: 500 1.2rem var(--font-gerstner);
    color: var(--color-gray-200);
  }

  &--terms-text-container {
    display: inline;
    text-align: left;
  }
}
