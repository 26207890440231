.maxi-player {
  display: block;
  height: 100%;
  position: relative;

  &__mobile {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (--from-tablet-landscape) {
      display: none;
    }
  }

  &__mobile-overlay {
    background: var(--color-cod-gray-100);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }

  &__mobile-menu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__mobile-menu__column {
    display: flex;
  }

  &__minimize {
    margin-right: 16px;
  }

  &__breadcrumb {
    color: var(--color-gin);
    font: 1.4rem/2.4rem var(--font-gerstner);
    margin-inline-end: auto;
  }

  &__mobile-menu-items {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin: -12px;
  }

  &__mobile-menu-item {
    align-items: center;
    color: var(--color-gin);
    display: flex;
    margin: 12px;
  }

  &__mobile-cover-wrapper {
    flex-grow: 2;
    margin: 0 auto 24px;
    position: relative;
    width: 100%;
  }

  &__mobile-cover {
    height: auto;
    left: 50%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    object-fit: cover;
  }

  &__mobile-wave {
    display: grid;
    grid-template-areas:
      'wave wave'
      'elapsed duration';
    margin-bottom: 16px;
  }

  &__mobile-wave-wrapper {
    grid-area: wave;
    margin-bottom: 8px;
  }

  &__elapsed,
  &__duration {
    font: 1rem/1.2rem var(--font-gerstner);
    letter-spacing: .04em;
  }

  &__elapsed {
    color: var(--color-gin);
    grid-area: elapsed;
  }

  &__duration {
    color: var(--color-gray-200);
    grid-area: duration;
    text-align: right;
  }

  &__mobile-title-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > button {
      display: flex;
    }
  }

  &__mobile-title {
    color: var(--color-gin);
    font: 500 1.6rem/2.4rem var(--font-gerstner);
    letter-spacing: .0093em;
    margin-bottom: 1px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__mobile-subtitle {
    color: var(--color-mercury);
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .0178em;
    margin-bottom: 4px;

    a {
      color: inherit;
    }
  }

  &__mobile-meta {
    align-items: center;
    display: flex;
    margin-bottom: 61px;
  }

  &__mobile-meta-items {
    align-items: center;
    display: flex;
    justify-content: left;
    margin-inline-end: auto;
  }

  &__mobile-meta-item {
    color: var(--color-gray-200);
    font: 300 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0333em;

    &:not(:last-of-type)::after {
      content: ' / ';
    }
  }

  &__mobile-other-versions {
    color: var(--color-gray-200);
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    letter-spacing: .0071em;
    padding-right: 17px;
    position: relative;

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid inherit;
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: border-top-color .2s;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__mobile-player-ctrls {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__mobile-player-ctrls--bar {
    justify-content: space-around;
    margin: 0 16px;
  }

  &__mobile-player-ctrl {
    margin: 0 8px 32px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    &:disabled {
      opacity: .3;
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    &--play,
    &--pause {
      svg {
        height: 56px;
        width: 56px;
      }
    }

    &--prev,
    &--next {
      svg {
        height: 48px;
        width: 48px;
      }
    }
  }

  &__desktop {
    display: none;

    @media (--from-tablet-landscape) {
      display: block;
    }
  }

  &__desktop-wrapper {
    column-gap: 32px;
    display: grid;
    grid-template-areas:
      'breadcrumb menu'
      'cover player'
      'up-next up-next';
    grid-template-columns: 317px minmax(0, 1fr);
    row-gap: 16px;
  }

  &__desktop-breadcrumb {
    color: var(--color-gin);
    font: 500 2.4rem/3.2rem var(--font-gerstner);
    grid-area: breadcrumb;
    letter-spacing: .015em;
    margin-bottom: 0;
  }

  &__desktop-menu {
    align-items: center;
    display: flex;
    grid-area: menu;
    position: relative;
  }

  &__desktop-menu-item {
    align-items: center;
    color: var(--color-boulder);
    display: flex;
    font: 1.2rem/2rem var(--font-gerstner);
    letter-spacing: .0208em;
    margin: 0 32px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
      position: absolute;
      right: 0;
    }

    i {
      margin-right: 4px;
    }
  }

  &__desktop-menu-item--active {
    color: var(--color-gin);
  }

  &__desktop-cover-wrapper {
    background: var(--color-dove-gray);
    grid-area: cover;
    height: 317px;
    width: 317px;
  }

  &__desktop-cover {
    height: 317px;
    width: 317px;
    object-fit: cover;
  }

  &__desktop-player {
    grid-area: player;
    position: relative;
  }

  &__desktop-player-menu {
    align-items: center;
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__desktop-player-menu-3-dots {
    display: flex;
    margin-left: 24px;
  }

  &__desktop-player-versions {
    color: var(--color-gin);
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .0071em;
    margin-right: 15px;
    padding-right: 12px;
    position: relative;

    &::after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--color-main-dynamic);
      content: '';
      height: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0;
    }
  }

  &__desktop-player-title {
    color: var(--color-gin);
    font: 500 3.2rem/4rem var(--font-gerstner);
    margin-bottom: 2px;
    max-width: calc(100% - 156px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__desktop-player-subtitle {
    color: var(--color-gin);
    font: 1.4rem/2.4rem var(--font-gerstner);
    letter-spacing: .0178em;
    margin-bottom: 7px;

    a {
      color: inherit;
    }
  }

  &__desktop-meta-items {
    color: var(--color-boulder);
    font: 1.4rem/1.6rem var(--font-gerstner);
    letter-spacing: .0285em;
    margin-bottom: 53px;
  }

  &__desktop-meta-item {
    &:not(:last-of-type)::after {
      content: ' / ';
    }
  }

  &__desktop-wave {
    margin-bottom: 26px;
  }

  &__desktop-player-ctrls-wrapper {
    align-items: center;
    display: flex;
    margin-bottom: 30px;
  }

  &__desktop-elapsed,
  &__desktop-duration {
    color: var(--color-gin);
    flex: 0 0 auto;
    font: 1.2rem/1.2rem var(--font-gerstner);
    letter-spacing: .0333em;
    min-width: 40px;
  }

  &__desktop-elapsed {
    text-align: start;
  }

  &__desktop-duration {
    color: var(--color-gray-200);
    text-align: end;
  }

  &__desktop-player-ctrls {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
  }

  &__desktop-player-ctrl {
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }

  &__desktop-player-ctrl-button {
    margin: 0 8px;

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @media (hover: hover) {
      &:not([disabled]):hover path {
        fill: var(--color-gin);
      }
    }

    &:disabled {
      opacity: .3;
    }

    &--active {
      path {
        fill: var(--color-gin);
      }
    }

    svg {
      height: 50px;
      width: 50px;
    }
  }

  &__desktop-up-next {
    grid-area: up-next;
  }
}
