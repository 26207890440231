.artist-detail-sections {
  &--divider {
    background-color: var(--color-cod-gray-500);
    height: .1rem;
    flex: 1;
    display: flex;
    width: 100%;
  }

  &--padding {
    margin: 2rem;

    @media (--from-tablet-landscape) {
      margin-left: 0;
    }
  }
}
