.player-queue {
  margin-bottom: 32px;
  padding: 32px 16px 0;

  @media (--from-tablet-landscape) {
    padding: 32px 0 0;
  }

  &__controls-container {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-bottom: 27px;

    @media (hover: hover) {
      &:hover svg {
        visibility: visible;
      }
    }
  }

  &__title {
    color: var(--color-gin);
  }

  &__edit-btn {
    color: var(--color-gray-200);
    display: flex;
    margin-right: 8px;
    transition: color .2s;

    > svg {
      fill: currentColor;
      visibility: hidden;
    }

    @media (hover: hover) {
      &:hover {
        color: var(--color-gin);
      }
    }
  }

  &__edit-mode-actions {
    border-left: 1px solid var(--color-cod-gray-400);
    display: flex;
    gap: 8px;
    padding-left: 16px;

    > button {
      color: var(--color-gray-200);
      display: flex;
      transition: color .2s;

      > svg {
        fill: currentColor;
        stroke: currentColor;
      }

      @media (hover: hover) {
        &:hover {
          color: var(--color-gin);
        }
      }
    }
  }

  &__edit-mode-open {
    > svg {
      visibility: visible;
    }
  }
}
