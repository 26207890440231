.manage-profile-form {
  background-color: transparent;
  border: none;

  @media (--from-tablet-landscape) {
    background-color: var(--color-cod-gray-300);
    padding: 0 1.5rem;
    max-width: 33.6rem;
    margin-top: 1rem;
    border: .1rem solid var(--color-cod-gray-500);
  }

  &--spacer {
    height: 2rem;
  }

  &--add-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
