.title-view {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 2.4rem;

  &--has-right-component {
    @media (--from-tablet-landscape) {
      justify-content: space-between;
      flex-direction: row;
      flex: 1;
    }
  }

  &--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &--title {
    font: 500 2rem/3.2rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &--sub-title {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
  }

  &__right {
    @media (--from-tablet-landscape) {
      margin-left: auto;
    }
  }
}
