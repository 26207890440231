.player-wave {
  width: 100%;
  position: relative;

  &__wrapper {
    cursor: pointer;
    padding-bottom: 9.677%;/* (31:3 aspect ratio or 1302×126px to ensure even wave sizing) */
    position: relative;
    touch-action: none;
    width: 100%;
  }

  &__duration {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &__elapsed {
    --highlight-start: 0%;
    --highlight-end: 10%;
    --highlight-color: var(--color-supreme-red);
    inset: 0;
    position: absolute;
    background:
      linear-gradient(
        to right,
        transparent var(--highlight-start),
        var(--highlight-color) var(--highlight-start),
        var(--highlight-color) var(--highlight-end),
        transparent var(--highlight-end)
      );
    mask: var(--mask-url) 0 0/100% 100% no-repeat;
  }

  & img {
    --dim-start: 100%;
    --dim-end: 0%;
    --dimmed-color: #0003;
    mask-image:
      linear-gradient(
        to right,
        var(--dimmed-color) var(--dim-end),
        black var(--dim-end),
        black var(--dim-start),
        var(--dimmed-color) var(--dim-start)
      );
  }
}
