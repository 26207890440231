.submit-music {
  &--checkbox {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    gap: 1rem;
  }

  &--select-date {
    --border-color: var(--color-tundora);
    border: .1rem solid var(--border-color);
    margin-bottom: 1rem;
    display: flex;
    flex: 1;
    flex-direction: row;
    transition: border .2s;
    font: 300 1.4rem var(--font-gerstner);

    :focus:not(.focus-visible) {
      outline: none;
    }

    @media (hover: hover) {
      &:hover {
        --border-color: var(--color-gin);
      }
    }
  }

  &--select-date-input {
    background-color: transparent;
    font: 500 1.4rem var(--font-gerstner);
    color: var(--color-gin);
    min-height: 4.2rem;
    width: 100%;
    height: 100%;
    box-shadow: none;
    padding: 0 .8rem;
    border: none;
  }
}
