.artist-portal-header {
  &__header-logo {
    margin-bottom: 8px;
  }

  &__logo {
    border-radius: .8rem;
  }

  &__text-container {
    margin-bottom: 2.4rem;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header-main-text {
    font: 500 3.2rem/4rem var(--font-gerstner);
    color: var(--color-gin);
  }

  &__header-text {
    font: 500 1.2rem/1.6rem var(--font-gerstner);
    color: var(--color-gray-200);
  }
}
